import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import "magnific-popup";

import aboutimg from "../../../assets/img/newImages/About/savaj.jpg";
import videobg from "../../../assets/img/text-block/04.jpg";

const featuresposts = [
    { icon: "flaticon-ring", title: "Diamond Ring" },
    { icon: "flaticon-bracelet", title: "Bracelets" },
    { icon: "flaticon-necklace", title: "Necklaces" },
    { icon: "flaticon-bracelet-2", title: "Pendants" },
    { icon: "flaticon-earrings", title: "Earrings" },
];
class About extends Component {
    // componentDidMount() {
    //     function popup() {
    //         $(".popup-video").magnificPopup({
    //             type: "iframe",
    //         });
    //     }
    //     popup();
    // }
    render() {
        return (
            <section className="about-section pt-115 pb-115">
                <div className="container craftsmanshipHeader">
                    <div className="section-title about-title text-center">
                        <span className="title-tag">
                            Fashion  <span>Forward</span>
                        </span>
                        <p>
                            We have nurtured our craftsmanship of working with
                            Polki and Uncut diamonds to craft unique and
                            custom-made fine jewellery and accessories. This
                            genius is employed to create dazzling collections of
                            diamond jewellery and accessories suited for
                            different tastes and special occasions.
                        </p>
                    </div>
                </div>
            </section>
        );
    }
}

export default About;

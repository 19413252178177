import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import img3 from "../../../assets/img/newImages/Home/BannerTwo.jpg";
import img2 from "../../../assets/img/newImages/Home/b3.jpg";
import { Player } from "video-react";
import farahanbodi from '../../../assets/img/media/WhatsApp Video 2023-11-14 at 6.13.59 AM.mp4'
import bryantpark from '../../../assets/img/media/savajnyc.mp4'
import img1 from "../../../assets/img/media/PHOTO-2023-08-22-11-17-29-1.jpg";
import img5 from "../../../assets/img/media/savajfeature2.jpg";
console.log("abhi\nbhabh");



const blogposts = [
    {
        img: img5,
        title: 'As featured in Vogue during Cannes Film Festival at Hotel Martinez Cannes, France for Vogue ',
        postdate: "28th Aug 2022",
        authorname: "Admin",
        type: "image",
        text: '-Dress:Gambattista Valli. \n Jewelry: Savaj Jewels . \n  Photography: Diana Catch.  Stylist: SimiJ Stylings. \n Hair: Koan Cannes.',

    }
    ,
    {
        img: img1,
        title: "Crafting Timeless Beauty: The Artistry of Savaj Jewels",
        postdate: "28th Aug 2022",
        authorname: "Admin",
        type: "image",
        text: "In the realm of fine jewelry, there exists a name that resonates with desire and embraces  .",
    },
    {
        video: farahanbodi,
        title: "Farhana Bodi of Dubai Bling Netflix",
        postdate: "28th Aug 2022",
        authorname: "Admin",
        type: "video",
        text: "Farhana Bodi of Dubai Bling Netflix at Paris Fashion Week in Savaj Jewels",
    },
    {
        video: bryantpark,
        title: "Savaj Jewels featured at Bryant Park New York Fashion Week.",
        postdate: "28th Aug 2022",
        authorname: "Admin",
        type: "video",
        text: "Savaj jewels and Savaj Couture embellishments proudly adorned New York Fashion Weeks runway at Bryant Park this past month.@bryantpark @nycfashionweek @savajjewels@savajcouture",
    },
    // {
    //     img: img1,
    //     title: "New Retro Collection of Pendants and Ring sets.",
    //     postdate: "28th Aug 2022",
    //     authorname: "Admin",
    //     text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
    // },
    // {
    //     img: img2,
    //     title: "Special Wedding Rings Sets for Him and for Her.",
    //     postdate: "28th Aug 2022",
    //     authorname: "Admin",
    //     text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
    // },
    // {
    //     img: img3,
    //     title: "Matching jewelry Sets with your Outwear.",
    //     postdate: "28th Aug 2022",
    //     authorname: "Admin",
    //     text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
    // },
];
class MediaShowcase extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    render() {
        const settings = {
            slidesToShow: 3,
            slidesToScroll: 1,
            fade: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 4000,
            arrows: false,
            dots: false,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        };
        return (
            <section className="latest-news pt-115 pb-115">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-8 col-sm-7">
                            <div className="section-title">
                                <h2>We've been featured</h2>
                                <p>
                                    Be it while adorning the wealthiest family of
                                    India or being showcased at Film festivals
                                    for the audience to enjoy, we have done it
                                    all. Here’s a glimpse of our grace.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-4 col-sm-5 d-none d-sm-block">
                            <div className="latest-post-arrow arrow-style text-right">
                                <div
                                    className="slick-arrow prev-arrow"
                                    onClick={this.previous}
                                >
                                    <i className="fal fa-arrow-left" />
                                </div>
                                <div
                                    className="slick-arrow next-arrow"
                                    onClick={this.next}
                                >
                                    <i className="fal fa-arrow-right" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Latest post loop */}
                    <Slider
                        className="row latest-post-slider mt-80"
                        ref={(c) => (this.slider = c)}
                        {...settings}
                    >
                        {blogposts.map((item, i) => (
                            <div key={i} className="col-lg-12">
                                <div className="latest-post-box">
                                    {
                                        item.type === "image" ?
                                            <div
                                                className="post-img"
                                                style={{
                                                    backgroundImage:
                                                        "url(" + item.img + ")",
                                                }}
                                            />
                                            :
                                            <div
                                                className="post-img">

                                                <Player
                                                    autoPlay={true}
                                                    loop={true}
                                                    muted
                                                    // poster={videobg}
                                                    style={{ width: "100%", objectFit: "cover" }}
                                                    controls={false}
                                                    className="popup-video h-100 w-100 HomeVideo"
                                                >
                                                    <source style={{ width: "100%", objectFit: "cover" }} src={item?.video} />
                                                </Player>
                                            </div>
                                    }
                                    <div className="post-desc" >
                                        {/* <ul className="post-meta">
                                            <li>
                                                <Link to="#">
                                                    <i className="fal fa-calendar-alt" />
                                                    {item.postdate}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <i className="fal fa-user" />
                                                    By {item.authorname}
                                                </Link>
                                            </li>
                                        </ul> */}
                                        <h4>
                                            <Link to="/blog-detail">
                                                {item.title}
                                            </Link>
                                        </h4>
                                        <p>{item.text}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div >
            </section >
        );
    }
}

export default MediaShowcase;

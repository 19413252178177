import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import ctabg from '../../../assets/img/others/product.png';

class Cta extends Component {
    render() {
        return (
            <section className="pt-115 pb-115 bg-white cta-sec" style={{ backgroundImage: "url("+ ctabg +")" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="block-text">
                                <div className="section-title mb-20">
                                    <span className="title-tag">Buy Now</span>
                                    <h2>Rare Collections.</h2>
                                </div>
                                
                                <p className="pr-50">
                                   
                                </p>
                                <Link to="/shop-left" className="main-btn btn-filled mt-40">Shop now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default Cta;
import React, { Component, Fragment } from "react";
import About from "./About";
import Handpicked from "./Handpicked";
import Handpick from "./Handpick";
import Productslider from "./Productslider";

class Content extends Component {
    render() {
        return (
            <Fragment>
                <About />
                <Handpicked />
                <Handpick />
                <Productslider/>
            </Fragment>
        );
    }
}

export default Content;

import React, { Component } from "react";
import { Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import bg from "../../../assets/img/bg/01.jpg";
import tbimg1 from "../../../assets/img/media/20230918_123612.jpg";
import tbimg2 from "../../../assets/img/media/diamondsjewelry.jpg";
import tbimg3 from "../../../assets/img/media/diamondsjewelry2.jpg";
import tbimg4 from "../../../assets/img/media/pixelup_1699913994057.jpg";
import polkyVideo from '../../../assets/video/videosavajpolki.mp4'
import { Player } from "video-react";
class Images extends Component {
  render() {
    return (
      <section
        className="room-type-section "
      // style={{ backgroundImage: "url(" + bg + ")" }}
      >
        <Tab.Container defaultActiveKey="pendant">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="section-title text-lg-left text-center">
                  {/* <span className="title-tag">50 % Discount </span> */}
                  {/* <h2>Jewellery </h2> */}
                </div>
              </div>

            </div>
            {/* <video autoPlay controls muted>
                            <source src={polkyVideo} type="video/mp4" autoPlay muted />
                            Your browser does not support the video tag.
                        </video> */}
            <Tab.Content className="mt-65">
              <Tab.Pane eventKey="pendant">
                <div className="room-items">
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 col-sm-6">
                          <div className="room-box">
                            <div
                              className="room-bg"
                              style={{
                                backgroundImage:
                                  "url(" +
                                  tbimg1 +
                                  ")",
                              }}
                            ></div>
                            <div className="room-content">
                              {/* <span className="room-count">
                                                                <i className="fal fa-th" />
                                                                05 Products
                                                            </span> */}
                              <h3>
                                {/* <Link to="/shop-detail">
                                  Agonfly
                                  Jewelry
                                </Link> */}
                              </h3>
                            </div>
                            {/* <Link
                              to="/shop-detail"
                              className="room-link"
                            >
                              <i className="fal fa-arrow-right" />
                            </Link> */}
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                          <div className="room-box">
                            <div
                              className="room-bg"
                              style={{
                                backgroundImage:
                                  "url(" +
                                  tbimg2 +
                                  ")",
                              }}
                            ></div>
                            <div className="room-content">
                              {/* <span className="room-count">
                                                                <i className="fal fa-th" />
                                                                10 Products
                                                            </span> */}
                              <h3>
                                {/* <Link to="/shop-detail">
                                  Coin Gold
                                  Necklace
                                </Link> */}
                              </h3>
                            </div>
                            {/* <Link
                              to="/shop-detail"
                              className="room-link"
                            >
                              <i className="fal fa-arrow-right" />
                            </Link> */}
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="room-box extra-wide">
                            <div
                              className="room-bg"
                              style={{
                                backgroundImage:
                                  "url(" +
                                  tbimg3 +
                                  ")",
                              }}
                            />
                            <div className="room-content">
                              {/* <span className="room-count">
                                                                <i className="fal fa-th" />
                                                                03 Products
                                                            </span> */}
                              <h3>
                                {/* <Link to="/shop-detail">
                                  Gold
                                  Bracelet
                                  Hall Of Fame
                                </Link> */}
                              </h3>
                            </div>
                            {/* <Link
                              to="/shop-detail"
                              className="room-link"
                            >
                              <i className="fal fa-arrow-right" />
                            </Link> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div
                        className="room-bg"
                        style={{
                          backgroundImage:
                            "url(" +
                            tbimg4 +
                            ")",
                          height: "100%",
                          backgroundSize: "container",
                          backgroundSize: "cover"
                        }}
                      ></div>
                      {/* <Player
                        autoPlay={true}
                        loop={true}
                        muted
                        // poster={videobg}
                        c
                        controls={false}
                        className="popup-video h-100 w-100 HomeVideo"
                      >
                        <source src={polkyVideo} />
                      </Player> */}
                      {/* <div className="room-content">
                                                  
                                                    <h3>
                                                        <Link to="/shop-detail">
                                                            Polki Jewelry
                                                        </Link>
                                                    </h3>
                                                </div>
                                                <Link
                                                    to="/shop-detail"
                                                    className="room-link"
                                                >
                                                    <i className="fal fa-arrow-right" />
                                                </Link> */}
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </section>
    );
  }
}

export default Images;

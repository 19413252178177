import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import "magnific-popup";

import videobg from "../../../assets/img/newImages/Home/videobg.jpg";
import video from "../../../assets/img/newImages/Home/MallikaSinghaniaSavaj.mp4";
import { Player } from "video-react";

class Video extends Component {
    componentDidMount() {
        // function popup() {
        //     $(".popup-video").magnificPopup({
        //         type: "iframe",
        //     });
        // }
        // popup();
    }
    render() {
        return (
            <div className="intro-video overlapped bg-white mt-30">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-sm-12 p-0">
                            <Player
                                poster={videobg}
                                className="popup-video HomeVideo"
                            >
                                <source src={video} />
                            </Player>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Video;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Productslider from "./Productslider";

const pickproducts = [
    {
        icon: "flaticon-earrings",
        title: "Artifical Earings",
        text: "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet",
    },
    {
        icon: "flaticon-bracelet",
        title: "Bracelet Curb",
        text: "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet",
    },
    {
        icon: "flaticon-bracelet-1",
        title: "Azrouel Variable",
        text: "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet",
    },
    {
        icon: "flaticon-bracelet-2",
        title: "Ankle Bracelet",
        text: "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet",
    },
    {
        icon: "flaticon-ring",
        title: "Pointer Ring",
        text: "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet",
    },
    {
        icon: "flaticon-necklace",
        title: "Copper Necklace",
        text: "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet",
    },
];
class Handpick extends Component {
    render() {
        return (
            <section className="service-sec pt-115 pb-85">
                <div className="container">
                    <div className="section-title text-center mb-50">
                        <h2>Sparkle in Savaj</h2>
                        <p>
                            {" "}
                            Feast your eyes on the exquisite craftsmanship of
                            Indian artisans used to create custom made diamond
                            accessories for you{" "}
                        </p>
                    </div>
                </div>
            </section>
        );
    }
}

export default Handpick;

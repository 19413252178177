import React from 'react';
import Breadcrumbs from '../layouts/Breadcrumbs';
import { MetaTags } from 'react-meta-tags';
import Header from '../layouts/Header';
import { Fragment } from 'react';
import Jwelsale from '../sections/homemain/Jwelsale';
import Videos from '../sections/media/Videos';
import Images from '../sections/media/Images';

const MediaPresence = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>SAVAJ JEWELS | About Us</title>
        <meta
          name="description"
          content="Unveil your grace with jewellery that reflect your inner beauty"
        />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Media" }} />
      <div className=' container py-5'>
        {/* <h3 className='my-5'>Videos</h3> */}
        <Videos />
        <h3 className='my-5'>Savaj Style</h3>
        <Images />
      </div>
    </Fragment>

  );
}

export default MediaPresence;

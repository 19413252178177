import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Breadcrumb from "../layouts/Breadcrumbs";
import Instafeeds from "../layouts/Instafeeds";
import Footer from "../layouts/Footerthree";
import Content from "../sections/contact/Content";
import Footertwo from "../layouts/Footertwo";

class Contact extends Component {
    render() {
        return (
            <Fragment>
                <MetaTags>
                    <title>Savaj Jewels | Contact Us</title>
                    <meta
                        name="description"
                        content="Unveil your grace with jewellery that reflect your inner beauty"
                    />
                </MetaTags>
                <Header />
                <Breadcrumb breadcrumb={{ pagename: "Contact Us" }} />
                <Content />
                {/* <Instafeeds /> */}
                <Footertwo />
            </Fragment>
        );
    }
}

export default Contact;

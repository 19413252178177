import React, { Component } from "react";
import { Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import bg from "../../../assets/img/bg/01.jpg";
import tbimg1 from "../../../assets/img/bridal/WhatsApp Image 2023-11-17 at 8.29.06 PM.jpeg";
import tbimg2 from "../../../assets/img/bridal/WhatsApp Image 2023-11-17 at 8.29.07 PM.jpeg";
import tbimg3 from "../../../assets/img/bridal/WhatsApp Image 2023-11-17 at 8.29.08 PM.jpeg";
import tbimg4 from "../../../assets/img/newImages/Home/GoldBraceletHallOfFame.jpg";
import { Player } from "video-react";
import diamondVideo from '../../../assets/img/bridal/WhatsApp Video 2023-11-16 at 7.07.39 PM.mp4'
class Bridal extends Component {
  render() {
    return (
      <section
        className="room-type-section pt-115 pb-115"
      // style={{ backgroundImage: "url(" + bg + ")" }}
      >
        <Tab.Container defaultActiveKey="pendant">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="section-title  text-center">
                  {/* <span className="title-tag">50 % Discount </span> */}
                  <h2>Savaj Bridal Jewelry</h2>
                  <div className="w-100 d-flex mt-3 justify-content-center">

                    <p className="w-50 "> Diamond cut refers to the polish, proportions, and symmetry of a diamond, not the stone's shape,a chain featuring unique grooves in the links that allow the piece of jewelry to sparkle in the light</p>
                  </div>
                </div>
              </div>
            </div>
            <Tab.Content className="mt-65">
              <Tab.Pane eventKey="pendant">
                <div className="room-items">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="room-box extra-height">
                        <Player
                          autoPlay={true}
                          loop={true}
                          muted
                          // poster={videobg}
                          style={{ width: "100%", objectFit: "cover" }}
                          controls={false}
                          className="popup-video h-100 w-100 HomeVideo"
                        >
                          <source style={{ width: "100%", objectFit: "cover" }} src={diamondVideo} />
                        </Player>
                        <div className="room-content">
                          <h3>
                            {/* <Link to="/shop-detail">
                                                            Ariel Ring
                                                        </Link> */}
                          </h3>
                        </div>
                        <Link
                          to="/shop-detail"
                          className="room-link"
                        >
                          <i className="fal fa-arrow-right" />
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 col-sm-6">
                          <div className="room-box">
                            <div
                              className="room-bg"
                            // style={{
                            //     backgroundImage:
                            //         "url(" +
                            //         tbimg1 +
                            //         ")",
                            // }}
                            >
                              <img src={tbimg1} style={{ objectFit: 'cover', objectPosition: 'center' }} className="w-100 h-100" />
                            </div>
                            <div className="room-content">
                              <h3>
                                {/* <Link to="/shop-detail">
                                                                    Agonfly
                                                                    Jewelry
                                                                </Link> */}
                              </h3>
                            </div>
                            <Link
                              to="/shop-detail"
                              className="room-link"
                            >
                              <i className="fal fa-arrow-right" />
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                          <div className="room-box">
                            <div
                              className="room-bg"
                            // style={{
                            //     backgroundImage:
                            //         "url(" +
                            //         tbimg2 +
                            //         ")",
                            // }}
                            >
                              <img src={tbimg2} style={{ objectFit: 'cover', objectPosition: 'center' }} className="w-100 h-100" />
                            </div>
                            <div className="room-content">
                              <h3>
                                {/* <Link to="/shop-detail">
                                                                    Coin Gold
                                                                    Necklace
                                                                </Link> */}
                              </h3>
                            </div>
                            <Link
                              to="/shop-detail"
                              className="room-link"
                            >
                              <i className="fal fa-arrow-right" />
                            </Link>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="room-box extra-wide">
                            <div
                              className="room-bg"
                            // style={{
                            //     backgroundImage:
                            //         "url(" +
                            //         tbimg4 +
                            //         ")",
                            // }}
                            >
                              <img src={tbimg3} style={{ objectFit: 'cover', objectPosition: 'center' }} className="w-100 h-100" />
                            </div>
                            <div className="room-content">
                              <h3>
                                {/* <Link to="/shop-detail">
                                                                    Gold
                                                                    Bracelet
                                                                    Hall Of Fame
                                                                </Link> */}
                              </h3>
                            </div>
                            <Link
                              to="/shop-detail"
                              className="room-link"
                            >
                              <i className="fal fa-arrow-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </section>
    );
  }
}

export default Bridal;

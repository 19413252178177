import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import "magnific-popup";

import aboutimg from "../../../assets/img/newImages/About/savaj.jpg";
import videobg from "../../../assets/img/text-block/04.jpg";

const featuresposts = [
  { icon: "flaticon-ring", title: "Diamond Ring" },
  { icon: "flaticon-bracelet", title: "Bracelets" },
  { icon: "flaticon-necklace", title: "Necklaces" },
  { icon: "flaticon-bracelet-2", title: "Pendants" },
  { icon: "flaticon-earrings", title: "Earrings" },
];
class Vison extends Component {
  // componentDidMount() {
  //     function popup() {
  //         $(".popup-video").magnificPopup({
  //             type: "iframe",
  //         });
  //     }
  //     popup();
  // }
  render() {
    return (
      <>
        <div className="section-title text-center mb-40 mt-40">
          <h2>What Savaj Stands For?</h2>
        </div>

        <section className="about-section pt-40 pb-40">
          <div className="container">
            <div className="section-title about-title text-center">
              <span className="title-tag">
                Vision &<span>Purpose</span>
              </span>
              <p>
                <p>
                With designs that leave you craving with desire, and authentic
                Indian tradition seeping through their craftsmanship, Savaj
                Jewels, is claiming its position as one of the most unique and
                innovative fine jewellery brands globally. Coming from a family
                of reputed merchants with two generations of expertise in the
                high-end fine jewelry market, Savaj Jewels is known for creating
                ethnic handmade jewelry catering to some of the most affluential
                families of India and becoming known for its unique style and
                creative designs amongst high societies around the world. Since
                our inception in 2020, our ethos is based on a very important
                truth, a value proposition for all our customers – A Craftsman
                to Customer Promise.
                </p>
                <p>
                Our vision is to create stunning jewelry that not only amplifies
                the beauty of the individual wearing it but also reflects the
                individual’s uniqueness of style and personality simultaneously.
                Fine jewelry as the ultimate luxury accessory must make our
                clients feel they are shining their inner beauty on the outside
                for the world to see through the expression of the jewels and
                gemstone designs and selection they have created with us.
                </p>
                <p>

                Our understanding of making uncut fine diamond jewelry has
                enabled us to create and craft custom-made designs. It is this
                passion for intertwining the very traditional Rajasthan style of
                jewelry with a modern twist incorporating bright colors or
                modern design to our fine jewelry pieces that sets us apart from
                other fine jewelry brands.
                </p>
                <p>
                Drawing inspiration from our traditional roots and honing our
                crafting skills to appeal to modern tastes, we have curated a
                variety of collections, with each being a sign of class and
                beauty. Our advent into the ecommerce space has given us an
                opportunity to successfully deliver our jewelry anywhere across
                the globe. With this milestone, we continue to provide joy,
                crafted for every special memory, moment, and gift.
                </p>
                <p>
                Being true to their bespoke nature, each and every piece
                reflects our efforts and focuses on adorning the inner glow of
                every individual who can admire the beauty of our unique style
                and jewelry artistry.
                </p>
              </p>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Vison;

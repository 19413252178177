import React, { Component } from "react";
import $ from "jquery";

import aboutimg1 from "../../../assets/img/home/savajfashion.jpg";
import aboutimg2 from "../../../assets/img/newImages/Home/whoWeAReTwo.jpg";

class About extends Component {
    componentDidMount() {
        function parallaxscroll() {
            $(document).ready(function () {
                //parallax scroll
                $(window).on("scroll", function () {
                    var parallaxElement = $(".parallax_scroll_up"),
                        parallaxQuantity = parallaxElement.length;
                    window.requestAnimationFrame(function () {
                        for (var i = 0; i < parallaxQuantity; i++) {
                            var currentElement = parallaxElement.eq(i),
                                windowTop = $(window).scrollTop(),
                                elementTop = currentElement.offset().top,
                                elementHeight = currentElement.height(),
                                viewPortHeight =
                                    window.innerHeight * 0.5 -
                                    elementHeight * 0.5,
                                scrolled =
                                    windowTop - elementTop + viewPortHeight;
                            currentElement.css({
                                transform:
                                    "translate3d(0," +
                                    scrolled * -0.25 +
                                    "px, 0)",
                            });
                        }
                    });
                    var parallaxElement2 = $(".parallax_scroll_down"),
                        parallaxQuantity2 = parallaxElement2.length;
                    window.requestAnimationFrame(function () {
                        for (var i = 0; i < parallaxQuantity2; i++) {
                            var currentElement2 = parallaxElement2.eq(i),
                                windowTop = $(window).scrollTop(),
                                elementTop = currentElement2.offset().top,
                                elementHeight = currentElement2.height(),
                                viewPortHeight =
                                    window.innerHeight * 0.5 -
                                    elementHeight * 0.5,
                                scrolled =
                                    windowTop - elementTop + viewPortHeight;
                            currentElement2.css({
                                transform:
                                    "translate3d(0," +
                                    scrolled * 0.05 +
                                    "px, 0)",
                            });
                        }
                    });
                });
            });
        }
        parallaxscroll();
    }
    render() {
        return (
            <section className="about-section style-2 pt-115 pb-115">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-lg-6 wow fadeInLeft parallax_scroll_down"
                            data-wow-delay=".3s"
                        >
                            <img
                                src={aboutimg1}
                                className="img-fluid"
                                alt="img"
                            />
                            <p className="mt-20">
                                Our story speaks of our
                                vision that helps us marry the rich culture and
                                style of Indian culture with the finesse and
                                modern designs helps us create unique
                                masterpieces for patrons and celebrities from
                                all across the world.
                            </p>
                        </div>
                        <div
                            className="col-lg-6 wow fadeInRight parallax_scroll_up"
                            data-wow-delay=".3s"
                        >
                            <div className="abour-text mt-100">
                                <div className="section-title mb-30">
                                    {/* <span className="title-tag">about us</span> */}
                                    <h2>Who are we?</h2>
                                </div>
                                <p className="mb-20">
                                    With designs that leave you craving with
                                    desire, and authentic Indian tradition
                                    seeping through our craftsmanship, we as
                                    Savaj Jewels have created a new niche for
                                    lovers of fine jewelry.
                                </p>
                                <img src={aboutimg2} alt="img" />
                                <p className="mt-20">
                                    Empowered with passion and expertise
                                    spanning over two generations, we create
                                    bespoke jewelry and accessories that
                                    amplify the beauty of the individual while
                                    reflecting their uniqueness simultaneously
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default About;

import React, { Component } from "react";
import { Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import bg from "../../../assets/img/bg/01.jpg";
import tbimg1 from "../../../assets/img/home/blue-home.jpg";
import tbimg2 from "../../../assets/img/newImages/Home/CoinGoldNecklace.jpg";
import tbimg3 from "../../../assets/img/home/WhatsApp Image 2023-10-19 at 11.05.32 PM (1).jpeg";
import tbimg4 from "../../../assets/img/home/savaj polki4.jpg";
import polkyVideo from '../../../assets/video/videosavajpolki.mp4'
import { Player } from "video-react";
class Jwelsale extends Component {
    render() {
        return (
            <section
                className="room-type-section pt-115"
            // style={{ backgroundImage: "url(" + bg + ")" }}
            >
                <div className="section-title text-center mb-80">
                    <h2>Rajasthan Royal Polki Jewelry</h2>
                    <div className="w-100 d-flex mt-3 justify-content-center">

                        <p className=" w-50 text-center">
                            Polki is a diamond in its most natural form—uncut, unfaceted, and unpolished. The diamond does not undergo several stages of processing to take on its ultimate shape as polished diamonds do
                        </p>
                    </div>
                </div>
                <Tab.Container defaultActiveKey="pendant">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="section-title text-lg-left text-center">
                                    {/* <span className="title-tag">50 % Discount </span> */}
                                    {/* <h2>Jewellery </h2> */}
                                </div>
                            </div>

                        </div>
                        {/* <video autoPlay controls muted>
                            <source src={polkyVideo} type="video/mp4" autoPlay muted />
                            Your browser does not support the video tag.
                        </video> */}
                        <Tab.Content className="mt-65">
                            <Tab.Pane eventKey="pendant">
                                <div className="room-items">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="row">
                                                <div className="col-lg-6 col-sm-6">
                                                    <div className="room-box">
                                                        <div
                                                            className="room-bg"
                                                            style={{
                                                                backgroundImage:
                                                                    "url(" +
                                                                    // tbimg1 +
                                                                    ")",
                                                            }}
                                                        >
                                                            <img src={tbimg1} alt="newimahge" style={{ objectFit: 'cover', objectPosition: '' }} className="w-100 h-100" />
                                                        </div>
                                                        <div className="room-content">

                                                            <h3>
                                                                {/* <Link to="/shop-detail">
                                                                    Agonfly
                                                                    Jewelry
                                                                </Link> */}
                                                            </h3>
                                                        </div>
                                                        <Link
                                                            to="/shop-detail"
                                                            className="room-link"
                                                        >
                                                            <i className="fal fa-arrow-right" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-sm-6">
                                                    <div className="room-box">
                                                        <div
                                                            className="room-bg"
                                                        // style={{
                                                        //     backgroundImage:
                                                        //         "url(" +
                                                        //         tbimg2 +
                                                        //         ")",
                                                        // }}
                                                        >
                                                            <img src={tbimg3} alt="newimahge" style={{ objectFit: 'cover', objectPosition: 'center' }} className="w-100 h-100" />

                                                        </div>
                                                        <div className="room-content">
                                                            {/* <span className="room-count">
                                                                <i className="fal fa-th" />
                                                                10 Products
                                                            </span> */}
                                                            <h3>
                                                                {/* <Link to="/shop-detail">
                                                                    Coin Gold
                                                                    Necklace
                                                                </Link> */}
                                                            </h3>
                                                        </div>
                                                        <Link
                                                            to="/shop-detail"
                                                            className="room-link"
                                                        >
                                                            <i className="fal fa-arrow-right" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="room-box extra-wide">
                                                        <div
                                                            className="room-bg"
                                                        // style={{
                                                        //     backgroundImage:
                                                        //         "url(" +
                                                        //         tbimg4 +
                                                        //         ")",
                                                        // }}
                                                        >
                                                            <img src={tbimg4} style={{ objectFit: 'cover', objectPosition: 'center' }} className="w-100 h-100" />
                                                        </div>
                                                        <div className="room-content">
                                                            {/* <span className="room-count">
                                                                <i className="fal fa-th" />
                                                                03 Products
                                                            </span> */}
                                                            <h3>
                                                                {/* <Link to="/shop-detail">
                                                                    Gold
                                                                    Bracelet
                                                                    Hall Of Fame
                                                                </Link> */}
                                                            </h3>
                                                        </div>
                                                        <Link
                                                            to="/shop-detail"
                                                            className="room-link"
                                                        >
                                                            <i className="fal fa-arrow-right" />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mt-0 mt-3 mt-lg-0">
                                            {/* <div
                                                    className="room-bg"
                                                    style={{
                                                        backgroundImage:
                                                            "url(" +
                                                            // tbimg3 +
                                                            ")",
                                                    }}
                                                ></div> */}
                                            <Player
                                                autoPlay={true}
                                                loop={true}
                                                muted
                                                // poster={videobg}
                                                c
                                                controls={false}
                                                className="popup-video h-100 w-100 HomeVideo"
                                            >
                                                <source src={polkyVideo} />
                                            </Player>
                                            {/* <div className="room-content">
                                                  
                                                    <h3>
                                                        <Link to="/shop-detail">
                                                            Polki Jewelry
                                                        </Link>
                                                    </h3>
                                                </div>
                                                <Link
                                                    to="/shop-detail"
                                                    className="room-link"
                                                >
                                                    <i className="fal fa-arrow-right" />
                                                </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </section >
        );
    }
}

export default Jwelsale;

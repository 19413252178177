import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import "magnific-popup";

import aboutimg from "../../../assets/img/about/WhatsApp Image 2023-10-23 at 6.01.13 PM.jpeg";
import videobg from "../../../assets/img/text-block/04.jpg";

const featuresposts = [
    { icon: "flaticon-ring", title: "Diamond Ring" },
    { icon: "flaticon-bracelet", title: "Bracelets" },
    { icon: "flaticon-necklace", title: "Necklaces" },
    { icon: "flaticon-bracelet-2", title: "Pendants" },
    { icon: "flaticon-earrings", title: "Earrings" },
];
class About extends Component {
    // componentDidMount() {
    //     function popup() {
    //         $(".popup-video").magnificPopup({
    //             type: "iframe",
    //         });
    //     }
    //     popup();
    // }
    render() {
        return (
            <section className="about-section pt-115 pb-115">
                <div className="container">
                    {/* <div className="video-wrap video-wrap-two video-about mb-60" style={{ backgroundImage: "url(" + videobg + ")" }}>
                        <Link to="http://www.youtube.com/embed/watch?v=EEJFMdfraVY" className="popup-video"><i className="fas fa-play" /></Link>
                    </div> */}
                    <div className="section-title about-title text-center">
                        <span className="title-tag">
                            Our <span>Legacy</span>
                        </span>
                        <p>
                            Our story began in India, a land filled with
                            culturally rich artisans and expert jewellers
                            renowned for their craftsmanship of diamond
                            jewellery. Being from a family with over two
                            generations of expertise in the Polki and Uncut
                            diamond market, Savaj Jewels was born with a niche
                            focus to create customised and handmade ethnic fine
                            jewellery, catering clients from all walks of life.
                            Right from our inception in 2020, we have created
                            designs that help you leave an everlasting
                            impression matching your inner beauty. Our approach
                            to enhancing the way diamond jewellery is created
                            has helped us claim the position as one of the most
                            in demand fine jewellery brands globally
                        </p>
                    </div>
                    {/* <ul className="about-features">
                        {featuresposts.map((item, i) => (
                            <li key={i}>
                                <Link to="#">
                                    <i className={item.icon} />
                                    <i
                                        className={
                                            "hover-icon " + item.icon + ""
                                        }
                                    />
                                    <span className="title">{item.title}</span>
                                </Link>
                            </li>
                        ))}
                    </ul> */}
                    <div className="section-title text-center mb-70 mt-70">
                        <h2>Who is behind it?</h2>
                    </div>
                    <div className="about-text-box">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="about-img h-50">
                                    <img src={aboutimg} alt="images" className="w-100" style={{ height: "70vh", objectFit: "cover", objectPosition: "top" }} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-text">
                                    <h3>Meet The Mind Behind Savaj Jewels</h3>
                                    <p className="mb-4">
                                        Learning and observing her mother, grandfather and
                                        uncles,  elite jewelers from
                                        India, Simi was motivated to take the
                                        craft to next level. Her understanding
                                        of the craft and the modern tastes of
                                        the jewelery market has helped her
                                        create the new niche of{" "}
                                        <span className="customSpan">
                                            Customised Fine Jewelery
                                        </span>
                                        . Having built a concrete understanding
                                        of digital mediums and e-commerce space
                                        helped her create a unique value
                                        proposition through Savaj Jewels – A
                                        Craftsman to Customer Promise.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default About;

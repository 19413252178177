import React, { Component, Fragment } from "react";
import Cta from "./Cta";
import About from "./About";
import Jwelsale from "./Jwelsale";
import Accessories from "./Accessories";
import SectionTitle from "./SectionTitle";
import Gallery from "./Gallery";
import MediaShowcase from "./MediaShowcase";
import Contact from "./Contact";
import Video from "./Video";
import Bridal from "./Bridal";

class Content extends Component {
    render() {
        return (
            <Fragment>
                <Cta />
                <About />
                <Jwelsale />
                <Accessories />
                <Bridal />
                <Gallery />
                <MediaShowcase />
                <Contact />
            </Fragment>
        );
    }
}

export default Content;

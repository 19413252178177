import React, { useState } from "react";
import Contacthelper from "../../../helper/Contacthelper";
import ReCAPTCHA from "react-google-recaptcha";
import { Alert } from "react-bootstrap";
import ReactMapboxGl, { Layer, Feature } from "react-mapbox-gl";
import emailjs from 'emailjs-com';

class Content extends Contacthelper {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
        };
    }

    handleSubmit = async (e) => {

        e.preventDefault();

        const templateParams = {
            from_name: this.state.name,
            from_email: this.state.email,
            from_phone: this.state.phone,
            from_subject: this.state.subject,
            message: this.state.message,
        };
        try {

            emailjs.init('Xqpjq--puESmSrr0A');
            const resp = await emailjs.send('service_82v7iak', 'template_10dgxvp', templateParams)
            console.log(resp)
            //   toast.success("Thanks for registering. Pending admin approval")
        } catch (error) {
            console.log(error)
        }

    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    render() {
        const Map = ReactMapboxGl({
            accessToken:
                "pk.eyJ1IjoiYWJlZHNoIiwiYSI6ImNrNnRyZ3d4aDAyMzkzZXBoc3RsYnM0aGwifQ.yhr3W_OOI6xXElmSY8cyPg",
        });
        return (
            <section className="contact-part pt-115 pb-115">
                <div className="container">
                    {/* Contact Info */}
                    <div className="contact-info">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-sm-6 col-10">
                                <div className="info-box">
                                    <div className="icon">
                                        <i className="flaticon-home" />
                                    </div>
                                    <div className="desc">
                                        <h4>Instagram</h4>
                                        <div>
                                            <a href="https://instagram.com/savajjewels?igshid=NGVhN2U2NjQ0Yg==">
                                                @savajjewels
                                            </a>
                                        </div>
                                        <div className="mt-2">

                                            <a href="https://instagram.com/simijagtiani?igshid=NGVhN2U2NjQ0Yg=="> @simijagtiani</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-10">
                                <div className="info-box">
                                    <div className="icon">
                                        <i className="flaticon-phone" />
                                    </div>
                                    <div className="desc pb-4">
                                        <h4>Phone Number</h4>
                                        <p >
                                            +34690810227
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-10">
                                <div className="info-box">
                                    <div className="icon">
                                        <i className="flaticon-message" />
                                    </div>
                                    <div className="desc pb-4">
                                        <h4>Email Address</h4>
                                        <p>
                                            Savajjewels@gmail.com
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Contact Mapts */}
                    {/* <Map
                        // eslint-disable-next-line react/style-prop-object
                        style="mapbox://styles/mapbox/light-v10"
                        className="contact-maps mb-30"
                    >
                        <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
                            <Feature coordinates={[-77.04, 38.907]} zoom={11.5} />
                        </Layer>
                    </Map> */}
                    {/* Contact Form */}
                    <div className="contact-form">
                        <form onSubmit={this.handleSubmit} method="POST">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="input-group mb-30">
                                        <span className="icon">
                                            <i className="far fa-user" />
                                        </span>
                                        <input
                                            type="text"
                                            placeholder="Your full name"
                                            name="name"
                                            value={this.state.name}
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group mb-30">
                                        <span className="icon">
                                            <i className="far fa-envelope" />
                                        </span>
                                        <input
                                            type="email"
                                            placeholder="Enter email address"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group mb-30">
                                        <span className="icon">
                                            <i className="far fa-phone" />
                                        </span>
                                        <input
                                            type="text"
                                            placeholder="Add phone number"
                                            name="phone"
                                            value={this.state.phone}
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group mb-30">
                                        <span className="icon">
                                            <i className="far fa-book" />
                                        </span>
                                        <input
                                            type="text"
                                            placeholder="Select Subject"
                                            name="subject"
                                            value={this.state.subject}
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="input-group textarea mb-30">
                                        <span className="icon">
                                            <i className="far fa-pen" />
                                        </span>
                                        <textarea
                                            placeholder="Enter messages"
                                            name="message"
                                            value={this.state.message}
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <ReCAPTCHA
                                        sitekey="6LdxUhMaAAAAAIrQt-_6Gz7F_58S4FlPWaxOh5ib"
                                        onChange={this.reCaptchaLoaded.bind(
                                            this
                                        )}
                                        size="invisible"
                                    />
                                    <button
                                        type="submit"
                                        className="main-btn btn-filled"
                                    >
                                        Get Free Quote
                                    </button>
                                    {/* Form Messages */}
                                    <Alert
                                        variant="success"
                                        className="d-none mt-3 mb-0"
                                        id="server_response_success"
                                    >
                                        <strong>Success!</strong> Contact form
                                        has been successfully submitted.
                                    </Alert>
                                    <Alert
                                        variant="danger"
                                        className="d-none mt-3 mb-0"
                                        id="server_response_danger"
                                    >
                                        <strong>Oops!</strong> Something bad
                                        happened. Please try again later.
                                    </Alert>
                                    {/* Form Messages */}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section >
        );
    }
}

export default Content;

import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footertwo";
import Newsletter from "../layouts/Newsletter";
import Content from "../sections/homemain/Content";

class HomeMain extends Component {
    render() {
        return (
            <Fragment>
                <MetaTags>
                    <title>SAVAJ JEWELS | Homepage</title>
                    <meta
                        name="description"
                        content="Unveil your grace with jewellery that reflect your inner beauty"
                    />
                </MetaTags>
                <Newsletter />
                <Header />
                <Content />
                <Footer />
            </Fragment>
        );
    }
}

export default HomeMain;

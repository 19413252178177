import React from "react";
import Contacthelper from "../../../helper/Contacthelper";
import ReCAPTCHA from "react-google-recaptcha";
import { Alert } from "react-bootstrap";
import ReactMapboxGl, { Layer, Feature } from "react-mapbox-gl";

class Contact extends Contacthelper {
    render() {
        return (
            <section className="contact-part pt-115 pb-115">
                <div className="container">
                    <div className="section-title text-center mb-80">
                        <h2>Get Yourself A Savaj Jewel</h2>
                        <p>
                            Interested to create your own unique look with Savaj
                            Jewels? We are all ears
                        </p>
                    </div>
                    {/* Contact Form */}
                    <div className="contact-form">
                        <form onSubmit={this.handleSubmit} method="GET">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="input-group mb-30">
                                        <span className="icon">
                                            <i className="far fa-user" />
                                        </span>
                                        <input
                                            type="text"
                                            placeholder="Your full name"
                                            name="name"
                                            value={this.state.name}
                                            onChange={this.onNameChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group mb-30">
                                        <span className="icon">
                                            <i className="far fa-envelope" />
                                        </span>
                                        <input
                                            type="email"
                                            placeholder="Enter email address"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.onEmailChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group mb-30">
                                        <span className="icon">
                                            <i className="far fa-phone" />
                                        </span>
                                        <input
                                            type="text"
                                            placeholder="Add phone number"
                                            name="phone"
                                            value={this.state.phone}
                                            onChange={this.onPhoneChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group mb-30">
                                        <span className="icon">
                                            <i className="far fa-book" />
                                        </span>
                                        <input
                                            type="text"
                                            placeholder="Select Subject"
                                            name="subject"
                                            value={this.state.subject}
                                            onChange={this.onSubjectChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="input-group textarea mb-30">
                                        <span className="icon">
                                            <i className="far fa-pen" />
                                        </span>
                                        <textarea
                                            placeholder="Enter messages"
                                            name="message"
                                            value={this.state.message}
                                            onChange={this.onMessageChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <ReCAPTCHA
                                        sitekey="6LdxUhMaAAAAAIrQt-_6Gz7F_58S4FlPWaxOh5ib"
                                        onChange={this.reCaptchaLoaded.bind(
                                            this
                                        )}
                                        size="invisible"
                                    />
                                    <button
                                        type="submit"
                                        className="main-btn btn-filled"
                                    >
                                        Submit
                                    </button>
                                    {/* Form Messages */}
                                    <Alert
                                        variant="success"
                                        className="d-none mt-3 mb-0"
                                        id="server_response_success"
                                    >
                                        <strong>Success!</strong> Contact form
                                        has been successfully submitted.
                                    </Alert>
                                    <Alert
                                        variant="danger"
                                        className="d-none mt-3 mb-0"
                                        id="server_response_danger"
                                    >
                                        <strong>Oops!</strong> Something bad
                                        happened. Please try again later.
                                    </Alert>
                                    {/* Form Messages */}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        );
    }
}

export default Contact;

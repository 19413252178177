import React, { Component } from "react";
import { Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import bg from "../../../assets/img/bg/01.jpg";
import tbimg1 from "../../../assets/img/media/IMG-20230729-WA0008.jpg";
import tbimg2 from "../../../assets/img/media/20231114_011525.jpg";
import tbimg3 from "../../../assets/img/newImages/Home/ArielRing.jpg";
import tbimg4 from "../../../assets/img/newImages/Home/GoldBraceletHallOfFame.jpg";
import polkyVideo from '../../../assets/img/media/WhatsApp Video 2023-11-14 at 6.13.59 AM.mp4'
import polkyVideo2 from '../../../assets/video/Savajvideo2.mp4'
import { Player } from "video-react";
class Videos extends Component {
  render() {
    return (
      <section
        className="room-type-section "
      // style={{ backgroundImage: "url(" + bg + ")" }}
      >
        <Tab.Container defaultActiveKey="pendant">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="section-title text-lg-left text-center">
                  {/* <span className="title-tag">50 % Discount </span> */}
                  {/* <h2>Jewellery </h2> */}
                </div>
              </div>

            </div>
            {/* <video autoPlay controls muted>
                            <source src={polkyVideo} type="video/mp4" autoPlay muted />
                            Your browser does not support the video tag.
                        </video> */}
            <Tab.Content className="mt-65">
              <Tab.Pane eventKey="pendant">
                <div className="room-items">
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 col-sm-6">
                          <div className="room-box">
                            <div
                              className="room-bg"
                            // style={{
                            //   backgroundImage:
                            //     "url(" +
                            //     tbimg1 +
                            //     ")",
                            // }}
                            >
                              <img src={tbimg1} alt="newimahge" style={{ objectFit: 'cover', objectPosition: '' }} className="w-100 h-100" />
                            </div>
                            <div className="room-content">
                              {/* <span className="room-count">
                                                                <i className="fal fa-th" />
                                                                05 Products
                                                            </span> */}
                              <h3>
                                {/* <Link to="/shop-detail">
                                  Agonfly
                                  Jewelry
                                </Link> */}
                              </h3>
                            </div>
                            <Link
                              to="/shop-detail"
                              className="room-link"
                            >
                              <i className="fal fa-arrow-right" />
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                          <div className="room-box">
                            <div
                              className="room-bg"
                            // style={{
                            //   backgroundImage:
                            //     "url(" +
                            //     tbimg2 +
                            //     ")",
                            // }}
                            >
                              <img src={tbimg2} alt="newimahge" style={{ objectFit: 'cover', objectPosition: '' }} className="w-100 h-100" />
                            </div>
                            <div className="room-content">
                              {/* <span className="room-count">
                                                                <i className="fal fa-th" />
                                                                10 Products
                                                            </span> */}
                              <h3>
                                {/* <Link to="/shop-detail">
                                  Coin Gold
                                  Necklace
                                </Link> */}
                              </h3>
                            </div>
                            <Link
                              to="/shop-detail"
                              className="room-link"
                            >
                              <i className="fal fa-arrow-right" />
                            </Link>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="room-box extra-wide">
                            {/* <div
                              className="room-bg"
                              style={{
                                backgroundImage:
                                  "url(" +
                                  tbimg4 +
                                  ")",
                              }}
                            /> */}
                            <Player
                              autoPlay={true}
                              loop={true}
                              muted
                              // poster={videobg}
                              c
                              controls={false}
                              className="popup-video h-100 w-100 HomeVideo"
                            >
                              <source src={polkyVideo2} />
                            </Player>
                            <div className="room-content">
                              {/* <span className="room-count">
                                                                <i className="fal fa-th" />
                                                                03 Products
                                                            </span> */}
                              <h3>
                                {/* <Link to="/shop-detail">
                                  Gold
                                  Bracelet
                                  Hall Of Fame
                                </Link> */}
                              </h3>
                            </div>
                            <Link
                              to="/shop-detail"
                              className="room-link"
                            >
                              <i className="fal fa-arrow-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      {/* <div
                                                    className="room-bg"
                                                    style={{
                                                        backgroundImage:
                                                            "url(" +
                                                            // tbimg3 +
                                                            ")",
                                                    }}
                                                ></div> */}
                      <Player
                        autoPlay={true}
                        loop={true}
                        muted
                        // poster={videobg}
                        c
                        controls={false}
                        className="popup-video h-100 w-100 HomeVideo"
                      >
                        <source src={polkyVideo} />
                      </Player>
                      {/* <div className="room-content">
                                                  
                                                    <h3>
                                                        <Link to="/shop-detail">
                                                            Polki Jewelry
                                                        </Link>
                                                    </h3>
                                                </div>
                                                <Link
                                                    to="/shop-detail"
                                                    className="room-link"
                                                >
                                                    <i className="fal fa-arrow-right" />
                                                </Link> */}
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </section>
    );
  }
}

export default Videos;

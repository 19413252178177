import React, { Component, Fragment } from "react";
import About from "./About";
import Habout from "./Habout";
import Vison from "./Vison";

class Content extends Component {
    render() {
        return (
            <Fragment>
                <About />
                <Vison />
                <Habout />
                {/* <Core/> */}
                {/* <Counter/> */}
                {/* <News/> */}
            </Fragment>
        );
    }
}

export default Content;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import img1 from "../../../assets/img/fashion/WhatsApp Image 2023-11-16 at 6.54.49 PM.jpg";
import img2 from "../../../assets/img/fashion/pixelup_1697273286970.jpg";
import img3 from "../../../assets/img/fashion/WhatsApp Image 2023-11-16 at 6.59.19 PM.jpg";

const roomslidertwoposts = [
    {
        img: img1,
        title: "Get All Gold Jewels",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
        price: "345",
        weight: "10gm",
    },
    {
        img: img2,
        title: "Grab Silver Jewel",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
        price: "235",
        weight: "10gm",
    },
    {
        img: img3,
        title: "Buy All Diamond",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
        price: "1,345",
        weight: "10gm",
    },
];
function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div className="slick-arrow next-arrow" onClick={onClick}>
            <i className="fal fa-arrow-right" />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div className="slick-arrow prev-arrow" onClick={onClick}>
            <i className="fal fa-arrow-left" />
        </div>
    );
}
class Handpicked extends Component {
    render() {
        const settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: false,
            infinite: true,
            autoplay: false,
            autoplaySpeed: 4000,
            arrows: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            dots: false,
            centerMode: true,
            centerPadding: "28%",

            responsive: [
                {
                    breakpoint: 1600,
                    settings: {
                        centerPadding: "20%",
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        centerPadding: "15%",
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        centerPadding: "10%",
                    },
                },
                {
                    breakpoint: 576,
                    settings: {
                        centerPadding: "5%",
                    },
                },
            ],
        };
        return (
            <section className="room-slider bg-white pb-80 pt-100">
                <div className="container-fluid p-0">
                    <div className="section-title mb-80 text-center">
                        <h2>Savaj Jewellery</h2>
                        <p>
                            Witness the dazzle of bespoke diamond jewellery
                            curated for the modern you
                        </p>
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                        <Slider
                            className="row rooms-slider-two justify-content-center"
                            style={{ width: "85%" }}
                            {...settings}
                        >
                            {roomslidertwoposts.map((item, i) => (
                                <div key={i} className="col-lg-12">
                                    <div className="single-rooms-box">
                                        <div className="room-img ">
                                            {/* <div
                                            className="img"
                                            style={{
                                                backgroundImage:
                                                    "url(" + item.img + ")",
                                            }}
                                        /> */}
                                            <img src={item.img} alt="" className="w-100 h-100" style={{ objectFit: "cover", objectPosition: "center" }} />
                                        </div>
                                        {/* <div className="room-desc">
                                        <div className="row align-items-center">
                                            <div className="col-sm-8">
                                                <h3>
                                                    <Link to="/shop-detail">
                                                        {item.title}{" "}
                                                    </Link>
                                                </h3>
                                                <p>{item.text}</p>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="price">
                                                    ${item.price}
                                                    <span>/{item.weight}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </section>
        );
    }
}

export default Handpicked;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactWOW from "react-wow";
import $ from "jquery";
import "magnific-popup";

import img1 from "../../../assets/img/home/WhatsApp Image 2023-10-19 at 11.05.30 PM.jpeg";
import img2 from "../../../assets/img/home/savajpolki5.jpg";
import img3 from "../../../assets/img/newImages/Home/g3.jpg";
import img4 from "../../../assets/img/newImages/Home/g4.jpg";
import img5 from "../../../assets/img/newImages/Home/g5.jpg";
import Video from "./Video";

const galleryposts = [
    { img: img4, animationtime: ".3s" },
    { img: img5, animationtime: ".4s" },
    { img: img3, animationtime: ".5s" },
    { img: img4, animationtime: ".6s" },
    { img: img5, animationtime: ".7s" },
];
class Gallery extends Component {
    componentDidMount() {
        function popup() {
            $(".gallery-loop .popup-image").magnificPopup({
                type: "image",
                gallery: {
                    enabled: true,
                },
                mainClass: "mfp-fade",
            });
        }
        popup();
    }
    render() {
        return (
            <div className="gallery-wrappper pt-120 pb-120">
                <div className="section-title text-center mb-80">
                    <h2>The Savaj Look</h2>
                    <p>
                        People all around the world have chosen Savaj Jewels to
                        create an authentic look for their special moments. Have
                        a look at we enriched fine jewelry with our style{" "}
                    </p>
                </div>
                <div className="container">
                    <div className="col-12 ">
                        <div className="d-flex w-100 flex-column flex-lg-row gap-4" style={{ gap: "2rem" }}>
                            <div className="w-100">
                                <img src={img1} alt="" className="w-100 " />
                            </div>
                            <div className="w-100 ">

                                <img src={img2} alt="" className="w-100 " />
                            </div>
                        </div>
                        {/* {galleryposts.map((item, i) => (
                            <ReactWOW
                                key={i}
                                animation="fadeInUp"
                                data-wow-delay={item.animationtime}
                            >
                                <div className="single-gallery-image">
                                    <Link to={item.img} className="popup-image">
                                        <img src={item.img} alt="" />
                                    </Link>
                                </div>
                            </ReactWOW>
                        ))} */}
                    </div>
                    <Video />
                </div>
            </div >
        );
    }
}

export default Gallery;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Masonry from "react-masonry-component";
import ReactWOW from "react-wow";

// About img
import aboutimg1 from "../../../assets/img/about/hangbag2.jpg";
import aboutimg2 from "../../../assets/img/about/couture.jpg";
import aboutbottomimg from "../../../assets/img/newImages/About/styleCover.jpg";
import icon1 from '../../../assets/img/about/handbag (1).png'
import icon2 from '../../../assets/img/about/dress (1).png'
class Habout extends Component {
    render() {
        const imagesLoadedOptions = {
            itemSelector: ".col-sm-6",
            percentPosition: false,
            resize: true,
            fitWidth: true,
        };
        return (
            <>
                <div className="section-title text-center mb-40 mt-40">
                    <h2>Savaj Brands</h2>
                </div>
                <section className="about-section pt-115 pb-115">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <ReactWOW animation="fadeInLeft" data-delay=".3s">
                                <div className="col-lg-8 col-md-10">
                                    <Masonry
                                        className="row about-features-boxes fetaure-masonary"
                                        imagesLoadedOptions={
                                            imagesLoadedOptions
                                        }
                                    >
                                        <div className="d-flex flex-lg-row flex-column">

                                            <div className="col-sm-5">
                                                <div className="single-feature-box">
                                                    <div className="icon">
                                                        {/* <i className="flaticon-ring" /> */}
                                                        <img src={icon1} alt="handbag" style={{ objectPosition: "left" }} />
                                                    </div>
                                                    <h4>
                                                        <Link to="#">
                                                            Savaj Handbags
                                                        </Link>
                                                    </h4>
                                                    <p>
                                                        Elevate Your Style with Savaj Handbags: Discover exquisite handcrafted designs that redefine elegance and sophistication.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-7">
                                                <div
                                                    className="single-feature-box only-bg mt-30"
                                                    style={{
                                                        backgroundImage:
                                                            "url(" +
                                                            aboutimg1 +
                                                            ")",
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                        <div className="d-flex mt-4 flex-lg-row-reverse flex-column-reverse" >

                                            <div className="col-sm-7 mt-4">
                                                <div
                                                    className="single-feature-box only-bg mt-30"
                                                    style={{
                                                        backgroundImage:
                                                            "url(" +
                                                            aboutimg2 +
                                                            ")",
                                                    }}
                                                ></div>
                                            </div>
                                            <div className="col-sm-5">
                                                <div className="single-feature-box dark mt-30">
                                                    <div className="icon">
                                                        <img src={icon2} alt="handbag" />
                                                        {/* <i className="flaticon-dress" /> */}
                                                    </div>
                                                    <h4>
                                                        <Link to="#">
                                                            Savaj Couture
                                                        </Link>
                                                    </h4>
                                                    <p>
                                                        Savaj couture specialize in couture ladies clothing , one-of-a-kind embellishments and couturing
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Masonry>
                                </div>
                            </ReactWOW>
                            <ReactWOW animation="fadeInRight" data-delay=".3s">
                                <div className="col-lg-4 col-md-8 col-sm-10">
                                    <div className="abour-text pl-50 pr-50">
                                        <div className="section-title mb-30">
                                            {/* <span className="title-tag">
                                            Our Craftsmanship
                                            </span> */}
                                            <h2>Our Style</h2>
                                        </div>
                                        <p>
                                            Our traditional roots and
                                            understanding of the Polki and Uncut
                                            diamond jewellery craft has helped
                                            us channel our talent to create
                                            bespoke jewellery and accessories.
                                            Witness the genius (hyperlink) of
                                            this art now.
                                        </p>
                                        {/* <Link
                                            to="/contact"
                                            className="main-btn btn-filled mt-40"
                                        >
                                            {" "}
                                            Know More
                                        </Link> */}
                                    </div>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>
                    <div className="about-right-bottom">
                        <div className="about-bottom-img">
                            <img src={aboutbottomimg} alt="" />
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Habout;

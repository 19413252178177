import React, { Component } from "react";
import sliderimg1 from "../../../assets/img/home/WhatsApp Image 2023-11-16 at 6.50.13 PM.jpeg";
import sliderimg2 from "../../../assets/img/home/WhatsApp Image 2023-10-11 at 6.57.00 PM.jpeg";
import sliderimg3 from "../../../assets/img/media/diamondsjewelry.jpg";
import sliderimg4 from "../../../assets/img/home/1.jpeg";
import bannerbg from "../../../assets/img/newImages/Home/bannerbg.jpg";
import Videobg from "../../../assets/img/newImages/Home/BannerTwo.jpg";

class Cta extends Component {
    render() {
        const settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 4000,
            arrows: false,
            dots: false,
        };
        return (
            <section
                style={{
                    backgroundImage: "url(" + bannerbg + ")",
                    backgroundPosition: "right"
                }}
                className="text-block headerBgImage  with-pattern pt-200 pb-115"
            >
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-10  order-2 order-lg-1">
                            <div className="block-text">
                                <div className="section-title mb-20">
                                    <span className="title-tag">
                                        <span className="titleSpan">
                                            {" "}
                                            Welcome to
                                        </span>
                                        <br /> Savaj Jewels
                                    </span>
                                    <h2>
                                        Bespoke by craft, created to match your
                                        beauty
                                    </h2>
                                </div>
                                <p className="pr-50">
                                    Making fine jewelry that shines with
                                    Indian culture and craftsmanship paired with
                                    your modern tastes
                                </p>
                                {/* <Link
                                    to="/contact"
                                    className="main-btn btn-filled mt-40"
                                >
                                    See More
                                </Link> */}
                            </div>
                        </div>

                        <div
                            className="col-lg-6 col-md-10 order-1 order-lg-2 wow fadeInRight"
                            data-wow-delay=".3s"
                        >
                            <div
                                className="video-wrap video-wrap-two mb-small"
                            // style={{
                            //     backgroundImage: "url(" + Videobg + ")",
                            // }}
                            >
                                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-interval="3000">
                                    <div class="carousel-inner">
                                        <div class="carousel-item home-slider active ">
                                            <img class="d-block w-100" src={sliderimg1} alt="First slide" />
                                        </div>
                                        <div class="carousel-item home-slider">
                                            <img class="d-block w-100" src={sliderimg2} alt="Second slide" />
                                        </div>
                                        <div class="carousel-item home-slider">
                                            <img class="d-block w-100" src={sliderimg3} alt="Third slide" />
                                        </div>
                                        <div class="carousel-item home-slider">
                                            <img class="d-block w-100" src={sliderimg4} alt="fourth slide" style={{ objectFit: "contain" }} />
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>
                                {" "}
                                {/* <Link
                                    to="http://www.youtube.com/embed/watch?v=EEJFMdfraVY"
                                    // className="popup-video"
                                >
                                    <i className="fas fa-play" />
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Cta;
